@import "./src/styles/_foundation.scss";
.select {
  position: relative;
  &:hover {
    .select__arrow {
      svg {
        path {
          stroke: color(blue, link);
        }
      }
    }
  }
  .select__arrow {
    position: absolute;
    right: 0;
    top: spacing(5);
    width: spacing(8);
    height: spacing(8);
    pointer-events: none;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    font-family: font-family();
    font-size: font-size();
    line-height: line-height();
    border: none;
    border-bottom: 1px solid color(gray, tin);
    padding: spacing(2) 0;
    border-radius: 0;
    padding-right: spacing(14);
    cursor: pointer;

    &:hover {
      color: color(blue, link);
    }
    &:focus {
      color: color(blue, dark);
      border-bottom: 1px solid color(blue, dark);
    }
  }
}
