@import "./src/styles/_foundation.scss";
.intro {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: color(black);
  }
  p {
    max-width: 98%;
  }
  padding: spacing(16) 0;
  &.intro--titleOnly {
    text-align: center;
    h1,
    h2,
    h5 {
      max-width: 100%;
    }
  }
}
