@import "./src/styles/_foundation.scss";
.mood-gallery {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: color(black);
  }
  p {
    max-width: 98%;
    color: color(black);
  }
  padding: spacing(16) 0;
  .mood-gallery__row1 {
    align-items: flex-end;
  }
  .mood-gallery__title {
    padding-top: spacing(16);
  }
}
