@import "./src/styles/_foundation.scss";
.form-field {
  display: block;
  width: 100%;
  &.form-field--inline {
    display: inline-block;
    width: auto;
    vertical-align: top;
    & + .form-field--inline {
      margin-left: spacing(4);
    }
  }
  &.form-field--small {
    max-width: 120px;
  }
  &.form-field--mid {
    max-width: 240px;
  }
  &.form-field--large {
    max-width: 480px;
  }
  label {
    font-size: font-size(s);
    line-height: 1;
    color: color(blue, dark);
    height: spacing(6);
    display: block;
  }
  &.form-field--select {
    &:hover {
      label {
        color: color(blue, link);
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    &.form-field--inline {
      display: block;
      width: 100%;

      & + .form-field--inline {
        margin-left: 0;
        margin-top: spacing(12);
      }
    }
    &.form-field--small {
      max-width: 70%;
    }
    &.form-field--mid {
      max-width: 90%;
    }
  }
}
