@import "./src/styles/_foundation.scss";
.unit {
  margin-bottom: spacing(16);
  @media only screen and (max-width: breakpoint(tablet)) {
    .col-order-2 {
      order: 2;
    }
    .col-order-1 {
      order: 1;
    }
    .unit__image {
      margin-bottom: spacing(6);
    }
  }

  .unit__image {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .unit__info {
    .unit__info__price {
      font-size: heading-font-size(h2);
      line-height: heading-line-height(h2);
      font-weight: 700;
    }
    .unit__info__price__discount {
      font-size: font-size(s);
      line-height: line-height(s);
      font-weight: 500;
      color: color(blue, lake);
    }
    .unit__info__characteristics {
      margin: spacing(10) 0;
      h5 {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      ul.unit__characteristics {
        padding: 0;
        margin: 0;
        list-style: none;
        li.unit__characteristics__item {
          padding: spacing(2) 0;
          margin: 0;
          font-size: font-size(s);
          line-height: line-height(s);
          border-bottom: 1px solid color(gray, light);
        }
      }
    }
  }
}
