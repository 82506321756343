@import "./src/styles/_foundation.scss";
.tabs__mobile-container {
  pointer-events: none;
  position: relative;
  width: 100vw;


  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    margin-left: 0 - spacing(10);
    margin-right: 0 - spacing(10);
    margin-bottom: spacing(2);

    &:before {
      content: '';
      position: absolute;
      width: spacing(12);
      height: spacing(14);
      background-color: red;
      z-index: 20;
      pointer-events: none;
      top: 0;
      left: 0;
      background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  transform: rotate(180deg);
    }
    &:after {
      content: '';
      position: absolute;
      width: spacing(12);
      height: spacing(14);
      background-color: red;
      z-index: 20;
      pointer-events: none;
      top: 0;
      right: -1px;
      background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);

    }
  }
}
.tabs {
  border-bottom: 1px solid color(gray, light);
  pointer-events: all;

  .tabs__item {
    display: inline-block;
    font-weight: 700;
    font-size: font-size(b);
    padding: spacing(5) 0;
    margin: 0 spacing(6);
    a {
      color: color(blue, dark);
      &:hover {
        color: color(blue, dark);
      }
    }
    span {
      color: color(blue, dark);
      cursor: pointer;
      &:hover {
        color: color(blue, dark);
      }
    }
    &.tabs__item--active {
      border-bottom: 2px solid color(blue, dark);
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    padding-left: spacing(8);
    padding-right: spacing(8);
    position: relative;


    .tabs__item {
    font-size: font-size(s);
    line-height: line-height(s);
    }
  }
}
