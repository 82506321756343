@import "./src/styles/_foundation.scss";
.banner {
  .image {
    margin-bottom: spacing(4);
  }
  h1,
  h2,
  h3 {
    transition: transition();
  }
  a {
    color: color(blue, dark);
    text-decoration: none;
    &:hover {
      color: color(blue, link);
      .image {
        img {
          transform: scale(1.05);
        }
      }
      h1,
      h2,
      h3 {
        color: color(gray, stone);
      }
    }
  }
  &.banner--dark {
    a {
      color: color(white);
      &:hover {
        h1,
        h2,
        h3,
        h4 {
          color: color(blue, light);
        }
      }
    }
  }
}
