//GRID
@import "./src/styles/_foundation.scss";
//container responsive widths
$containerDesktopHD: 1152px;
$containerDesktop: 1152px;
$containerTablet: 100%;
$containerMobile: 100%;

// This is mixin which you will include in parent
// of columns. It kills default margin of inline-block
// elements.
.grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  & > div {
    vertical-align: middle;
  }

  &.grid--models {
    grid-template-columns: repeat(4, 1fr);
    gap: spacing(15);
    //Desktop Small
    @media only screen and (max-width: breakpoint(desktopSD)) {
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      gap: spacing(14);
    }
    //Tablet
    @media only screen and (max-width: breakpoint(tablet)) {
      gap: spacing(13);
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
    //Mobile
    @media only screen and (max-width: breakpoint(mobile)) {
      gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.grid--offers {
    grid-template-columns: repeat(3, 1fr);
    gap: spacing(15);
    //Desktop Small
    @media only screen and (max-width: breakpoint(desktopSD)) {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      gap: spacing(14);
    }
    //Tablet
    @media only screen and (max-width: breakpoint(tablet)) {
      gap: spacing(13);
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
    //Mobile
    @media only screen and (max-width: breakpoint(mobile)) {
      gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.grid--highlightedPlans {
    grid-template-columns: repeat(2, 1fr);
    gap: spacing(15);
    //Desktop Small
    @media only screen and (max-width: breakpoint(desktopSD)) {
      grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
      gap: spacing(14);
    }
    //Tablet
    @media only screen and (max-width: breakpoint(tablet)) {
      gap: spacing(13);
      grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    }
    //Mobile
    @media only screen and (max-width: breakpoint(mobile)) {
      gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .col1 {
    grid-column-end: span 1;
  }
  .col4 {
    grid-column-end: span 4;
  }
  .col5 {
    grid-column-end: span 5;
  }
  .col6 {
    grid-column-end: span 6;
  }
  .col8 {
    grid-column-end: span 8;
  }
  .col9 {
    grid-column-end: span 9;
  }
  .col10 {
    grid-column-end: span 10;
  }
  .col12 {
    grid-column-end: span 12;
  }
  .col15 {
    grid-column-end: span 15;
  }
  .col16 {
    grid-column-end: span 16;
  }
  .col20 {
    grid-column-end: span 20;
  }
  .col22 {
    grid-column-end: span 22;
  }
  .s1 {
    grid-column-start: 1;
  }
  .s2 {
    grid-column-start: 2;
  }
  .s3 {
    grid-column-start: 3;
  }
  .s4 {
    grid-column-start: 4;
  }
  .s5 {
    grid-column-start: 5;
  }
  .s6 {
    grid-column-start: 6;
  }
  .s7 {
    grid-column-start: 7;
  }
  .s8 {
    grid-column-start: 8;
  }
  .s9 {
    grid-column-start: 9;
  }
  .s10 {
    grid-column-start: 10;
  }
  .s11 {
    grid-column-start: 11;
  }
  .s12 {
    grid-column-start: 12;
  }
  .s13 {
    grid-column-start: 13;
  }
  .s14 {
    grid-column-start: 14;
  }
  .s15 {
    grid-column-start: 15;
  }
  .s16 {
    grid-column-start: 16;
  }
  .s17 {
    grid-column-start: 17;
  }
  .s18 {
    grid-column-start: 18;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .col4,
    .col5,
    .col6,
    .col7,
    .col8,
    .col9,
    .col10,
    .col11,
    .col12,
    .col13,
    .col14,
    .col15,
    .col16,
    .col17,
    .col18,
    .col19,
    .col20,
    .col21,
    .col22,
    .col23 {
      grid-column-end: span 20;
    }
    .s2,
    .s3,
    .s4,
    .s5,
    .s6,
    .s7,
    .s8,
    .s9,
    .s10,
    .s11,
    .s12,
    .s13,
    .s14,
    .s15,
    .s16,
    .s17 {
      grid-column-start: 3;
    }
    .col24m {
      grid-column-start: 1;
      grid-column-end: span 24;
    }
  }
}
