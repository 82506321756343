@import "./src/styles/_foundation.scss";
.model {
  margin-bottom: spacing(14);
  .model__hero {
    position: relative;
    min-height: 177px;
    background-color: color(gray, dark);
    color: color(white);
    margin-bottom: spacing(16);
    .model__name {
      padding: spacing(7) spacing(9);
      font-weight: 700;
      font-size: font-size(b);
    }
    .model__image {
      position: absolute;
      bottom: 0 - spacing(14);
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .model__content {
    text-align: center;
    font-size: font-size(s);
    line-height: line-height(s);
    .model__content__button {
      padding-bottom: spacing(5);
    }
  }
  &.model--tin {
    .model__hero {
      background-color: color(gray, tin);
      color: color(black);
    }
  }
  &.model--black {
    .model__hero {
      background-color: color(black);
    }
  }
  &.model--red {
    .model__hero {
      background-color: color(red, dark);
    }
  }
  &.model--scarlet {
    .model__hero {
      background-color: color(red, scarlet);
    }
  }
  &.model--stone {
    .model__hero {
      background-color: color(gray, stone);
    }
  }
  &.model--blue {
    .model__hero {
      background-color: color(blue, lake);
    }
  }

  &.model--onlyImage {
    .model__hero {
      position: relative;
      min-height: none;
      padding-top: 35%;
    }
  }
}
