@import "./src/styles/_foundation.scss";
.basic {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: color(black);
  }
  p {
    max-width: 98%;
    color: color(black);
  }
  padding: spacing(16) 0;
  &.basic--link {
    a {
      text-decoration: none;
    }
    p {
      pointer-events: none;
      text-decoration: none;
    }
  }
}
