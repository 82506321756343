@import "./src/styles/_foundation.scss";
.breadcrumbs {
  padding-top: spacing(6);
  padding-bottom: spacing(16);
  font-size: font-size(s);
  .breadcrumbs__item {
    display: inline-block;
    padding-left: spacing(5);
    position: relative;
    &:before {
      content: "";
      margin-right: 8px;
      margin-top: 7px;
      width: 0px;
      height: 0px;
      border-left: 5px solid color(blue, dark);
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      display: inline-block;
    }
    &:first-child {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
  &.breadcrumbs--withCurrent {
    .breadcrumbs__item {
      &:last-child {
        a {
          color: color(black);
          border-bottom: 0;
          pointer-events: none;
          font-weight: bold;
        }
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    display: none;
  }
}
