@import "./src/styles/_foundation.scss";
.two-column {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: color(black);
  }
  p {
    max-width: 98%;
    color: color(black);
  }
  padding: spacing(16) 0;
  .two-column__column1 {
  }
  .two-column__column2 {
    padding-top: spacing(8);
  }
}
