@import "./src/styles/_foundation.scss";
.text-field {
  width: 100%;
  input[type="text"],
  input[type="number"],
  input[type="date"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    font-family: font-family();
    font-size: font-size();
    line-height: line-height();
    border: none;
    border-bottom: 1px solid color(gray, tin);
    padding: spacing(2) 0;
    &:focus {
      border-bottom: 1px solid color(blue, dark);
    }
  }
  &.text-field--error {
    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="email"],
    input[type="tel"] {
      border-bottom: 1px solid color(red);
    }
  }
  .text-field__error-message {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(red);
  }
  .text-field__help-text {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(gray, stone);
  }
}
