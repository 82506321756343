@import "./src/styles/_foundation.scss";
.form-row {
  display: flex;
  margin-bottom: spacing(12);
  &:last-child {
    margin-bottom: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    display: block;
  }
}
