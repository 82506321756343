@font-face {
  font-family: "vw-text";

  src: url("/resources/fonts/VWTextWeb-Regular.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWTextWeb-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWTextWeb-Regular.woff") format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWTextWeb-Regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWTextWeb-Regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWTextWeb-Regular.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vw-text";

  src: url("/resources/fonts/VWTextWeb-Light.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWTextWeb-Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWTextWeb-Light.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWTextWeb-Light.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWTextWeb-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWTextWeb-Light.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "vw-text";
  src: url("/resources/fonts/VWTextWeb-Bold.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWTextWeb-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWTextWeb-Bold.woff") format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWTextWeb-Bold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWTextWeb-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWTextWeb-Bold.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "vw-display";
  src: url("/resources/fonts/VWHeadWeb-Regular.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWHeadWeb-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWHeadWeb-Regular.woff") format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWHeadWeb-Regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWHeadWeb-Regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWHeadWeb-Regular.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vw-display";

  src: url("/resources/fonts/VWHeadWeb-Light.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWHeadWeb-Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWHeadWeb-Light.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWHeadWeb-Light.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWHeadWeb-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWHeadWeb-Light.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "vw-display";
  src: url("/resources/fonts/VWHeadWeb-Bold.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWHeadWeb-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWHeadWeb-Bold.woff") format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWHeadWeb-Bold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWHeadWeb-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWHeadWeb-Bold.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "vw-display";
  src: url("/resources/fonts/VWHeadWeb-ExtraBold.eot"); /* IE9 Compat Modes */
  src: url("/resources/fonts/VWHeadWeb-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/resources/fonts/VWHeadWeb-ExtraBold.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/resources/fonts/VWHeadWeb-ExtraBold.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("/resources/fonts/VWHeadWeb-ExtraBold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/resources/fonts/VWHeadWeb-ExtraBold.svg#svgFontName") format("svg"); /* Legacy iOS */

  font-weight: 700;
  font-style: normal;
}
