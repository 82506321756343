@import "./src/styles/_foundation.scss";
.footer {
  font-size: font-size(s);
  padding-bottom: spacing(16);
  .footer__nav {
    border-top: spacing(1) solid color(blue, dark);
    padding: spacing(16) 5% spacing(12);
    margin-top: spacing(16);
    h5 {
      font-size: font-size(s);
      color: color(black);
    }
    .footer__nav__link-list {
      font-size: font-size(xs);

      a {
        padding: spacing(2) 0;
        display: block;
        width: 100%;
        color: color(blue, dark);
        text-decoration: none;
        line-height: spacing(8);
        &:hover {
          color: color(blue, link);
        }
      }
    }
    .footer__nav__grid {
    }
    .footer__nav__secondary {
      font-size: font-size(xs);
      padding-top: spacing(12);
      display: flex;
      justify-content: space-between;

      .footer__nav__secondary__links {
        a {
          color: color(blue, dark);
          text-decoration: none;
          display: inline-block;
          position: relative;

          margin: 0 spacing(4);
          &:first-child {
            margin-left: 0;
          }

          &:hover {
            color: color(blue, link);
          }
        }
      }
      .footer__nav__secondary__sign {
      }
    }
  }
  .footer__legal {
    border-top: 1px solid color(gray, tin);
    padding-top: spacing(16);
    color: color(gray, stone);
    p {
      color: color(gray, stone);
    }
    h4 {
      color: color(gray, stone);
    }
    ol,
    ul {
      padding: 0 0 0 spacing(7);
      margin: 0;
      li {
        margin-bottom: spacing(4);
        margin-left: spacing(8);
      }
    }
    ol {
      list-style-type: none;
      counter-reset: list-counter;
      li {
        counter-increment: list-counter;
        position: relative;
        &:before {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0 - spacing(2, 11);
          content: counter(list-counter) ". ";
        }
      }
    }
  }
  @media only screen and (min-width: breakpoint(desktopHD)) {
    font-size: font-size(s);
    .footer__nav {
      h5 {
        font-size: font-size();
      }
      .footer__nav__link-list {
        font-size: font-size(s);
      }
      .footer__nav__secondary {
        font-size: font-size(s);
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .footer__nav {
      padding-left: 0;
      padding-right: 0;
      .col6 {
        margin-bottom: spacing(14);
      }
      .footer__nav__secondary {
        flex-direction: column-reverse;
        .footer__nav__secondary__links {
          a {
            margin: 0;
            display: inline;
          }
        }
        .footer__nav__secondary__sign {
          color: color(black);
          padding-bottom: spacing(10);
          font-size: font-size(xs);
        }
      }
    }
  }
}
