@import "./src/styles/_foundation.scss";
.fixed-buttons {
  position: fixed;
  bottom: spacing(6);
  right: spacing(6);
  z-index: 9999999999;
  .fixed-button {
    background-color: color(blue, mediterranean);
    color: color(blue, dark);

    height: spacing(10, 2);
    line-height: spacing(10, 2);
    font-size: font-size(s);
    border-radius: spacing(10);
    margin-bottom: spacing(6);
    z-index: 9999999999;
    a {
      height: spacing(10, 2);
      line-height: spacing(10, 2);
      padding: 0 spacing(12);
    }
    span {
      height: spacing(10, 2);
      line-height: spacing(10, 2);
      padding: 0 spacing(12);
      cursor: pointer;
    }
  }
}
