@import "./src/styles/_foundation.scss";
.offer {
  margin-bottom: spacing(14);
  .offer__hero {
    position: relative;
    min-height: 177px;
    background-color: color(gray, dark);
    color: color(white);
    margin-bottom: spacing(16);
    .offer__name {
      padding: spacing(7) spacing(9);
      font-weight: 700;
      font-size: font-size(b);
      .offer__type {
        font-size: font-size(s);
        font-weight: 400;
      }
    }
    .offer__image {
      position: absolute;
      bottom: 0 - spacing(14);
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .offer__content {
    text-align: center;
    font-size: font-size(s);
    line-height: line-height(s);
    padding: 0 spacing(6) spacing(6);
    p {
      margin-left: auto;
      margin-right: auto;
    }
    .offer__content__button {
      padding-bottom: spacing(5);
    }
  }
  &.offer--tin {
    .offer__hero {
      background-color: color(gray, tin);
      color: color(black);
    }
  }
  &.offer--black {
    .offer__hero {
      background-color: color(black);
    }
  }
  &.offer--red {
    .offer__hero {
      background-color: color(red, dark);
    }
  }
  &.offer--scarlet {
    .offer__hero {
      background-color: color(red, scarlet);
    }
  }
  &.offer--stone {
    .offer__hero {
      background-color: color(gray, stone);
    }
  }
  &.offer--blue {
    .offer__hero {
      background-color: color(blue, lake);
    }
  }

  &.offer--onlyImage {
    .offer__hero {
      position: relative;
      min-height: none;
      padding-top: 35%;
    }
  }
}
