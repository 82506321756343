@import "./src/styles/_foundation.scss";
.meta {
  font-size: font-size(s);
  font-weight: bold;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  &.meta--scrolled {
    .meta__background {
      height: spacing(15)-2px;
    }
  }
  .meta__background {
    background-color: color(white);
    height: spacing(12, 3)-2px;
    z-index: 5;
    opacity: 0.95;
    transition: transition(basic, basic, height);
  }
  .meta__content {
    display: flex;
    justify-content: space-between;
    padding: spacing(6) 4.17% spacing(5);
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .meta__content__menu {
      height: spacing(15);
      line-height: spacing(15);
      padding-top: spacing(1);
      button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
        display: flex;
        color: color(blue, night);
        cursor: pointer;
        svg {
          width: spacing(8);
          height: spacing(8);
          fill: color(blue, dark);
        }
        .meta__content__menu__text {
          height: spacing(8);
          line-height: spacing(8);
          margin-left: spacing(4);
          font-family: font-family();
          font-weight: 600;
          color: color(blue, dark);
        }
        &:hover {
          svg {
            fill: color(blue, link);
          }
          .meta__content__menu__text {
            color: color(blue, link);
          }
        }
      }
    }
    .meta__content__main {
      text-align: center;
    }
    .meta__content__right {
    }
  }
  .meta__separator {
    z-index: 15;
    position: absolute;
    top: spacing(10);
    left: 0;
    width: 100%;
    display: flex;
    height: spacing(15);
    pointer-events: none;
    .meta__separator__border {
      height: spacing(1);
      display: inline-block;
      background-color: color(blue, dark);
      margin-top: spacing(9);
      width: 100%;
      &:last-child {
        width: 45%;
      }
    }
    .meta__separator__vw {
      height: spacing(14);
      width: spacing(14);
      margin: 0 spacing(5);
      pointer-events: all;
      svg {
        height: spacing(14);
        width: spacing(14);
        fill: color(blue, dark);
      }
    }
  }
  &.meta-state-2 {
    .meta__background {
      opacity: 0;
    }
    .meta__content {
      .meta__content__main {
        pointer-events: none;
        opacity: 0;
      }
    }
    .meta__separator {
      .meta__separator__border {
        background-color: color(white);
      }
      .meta__separator__vw {
        svg {
          fill: color(white);
        }
      }
    }
    .meta__content {
      .meta__content__main {
        color: color(white);
      }
      .meta__content__right {
        a {
          color: color(white);
        }
      }
      .meta__content__menu {
        button {
          .meta__content__menu__text {
            color: color(white);
          }
          svg {
            fill: color(white);
          }
          &:hover {
            svg {
              fill: color(blue, light);
            }
            .meta__content__menu__text {
              color: color(blue, light);
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: breakpoint(tablet)) {
    .meta__separator {
      top: spacing(9);
      .meta__separator__vw {
        height: spacing(12);
        width: spacing(12);
        margin: spacing(3) spacing(5) 0;
        svg {
          height: spacing(12);
          width: spacing(12);
        }
      }
      .meta__separator__border {
        &:last-child {
          width: 100%;
        }
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .meta__content {
      .meta__content__right {
        display: none;
      }
      .meta__content__main {
        font-size: font-size(xs);
      }
    }
  }
}
