@import "./src/styles/_foundation.scss";
.plan {
  margin-bottom: spacing(14);
  &.plan--highlighted {
    .plan__hero {
      min-height: 250px;
      .plan__image {
        bottom: 0 - spacing(16);
      }
    }
  }
  .plan__hero {
    position: relative;
    min-height: 177px;
    background-color: color(gray, dark);
    color: color(white);
    margin-bottom: spacing(16);
    .plan__name {
      padding: spacing(7) spacing(9);
      font-weight: 700;
      font-size: font-size(b);
      .plan__model {
        font-size: font-size(s);
        font-weight: 400;
      }
    }
    .plan__image {
      position: absolute;
      bottom: 0 - spacing(14);
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .plan__content {
    text-align: center;
    font-size: font-size(s);
    line-height: line-height(s);
    padding: 0 spacing(6) spacing(6);
    p {
      margin-left: auto;
      margin-right: auto;
    }
    .plan__content__button {
      padding-bottom: spacing(5);
    }
  }
  &.plan--tin {
    .plan__hero {
      background-color: color(gray, tin);
      color: color(black);
    }
  }
  &.plan--black {
    .plan__hero {
      background-color: color(black);
    }
  }
  &.plan--red {
    .plan__hero {
      background-color: color(red, dark);
    }
  }
  &.plan--scarlet {
    .plan__hero {
      background-color: color(red, scarlet);
    }
  }
  &.plan--stone {
    .plan__hero {
      background-color: color(gray, stone);
    }
  }
  &.plan--blue {
    .plan__hero {
      background-color: color(blue, lake);
    }
  }

  &.plan--onlyImage {
    .plan__hero {
      position: relative;
      min-height: none;
      padding-top: 35%;
    }
  }
}
