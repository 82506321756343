@import "./src/styles/_foundation.scss";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;

  &.modal--image-only {
    .modal__body {
      min-height: 400px;
      max-height: 90vh;
      width: 1200px;
      max-width: 90vw;
      position: relative;
      .modal__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        z-index: 10;
      }
      .modal__header {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 20;
        pointer-events: none;
        .modal__header__close {
          .modal__header__close__button {
            pointer-events: all;
            background-color: color(white);
            &:hover {
              background-color: color(white);
              &:after,
              &:before {
                border-color: color(blue, link);
              }
            }
          }
        }
      }
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .modal__body {
    background-color: color(white);

    z-index: 20;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow-y: auto;
    max-height: calc(100% - 64px);
    transform: translate(-50%, -50%) translateY(60px);
    opacity: 0;
    transition-property: all;
    transition-duration: 300ms, 300ms;
    transition-timing-function: ease, ease;
    width: 40vw;
    .modal__header {
      padding: spacing(8) spacing(12, 2);
      h4 {
        margin: 0;
        color: color(black);
      }

      .modal__header__close {
        position: absolute;
        right: spacing(4);
        top: spacing(4);
        .modal__header__close__button {
          position: relative;
          width: spacing(12);
          height: spacing(12);
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            background-color: transparentize(color(gray, light), 0.6);
          }
          background-color: transparent;
          &:after {
            content: "";
            position: absolute;
            top: spacing(7);
            left: spacing(4);
            width: spacing(8);
            border-bottom: 1.25px solid color(blue, dark);
            transform: rotate(-45deg);
          }
          &:before {
            content: "";
            position: absolute;
            top: spacing(7);
            left: spacing(4);
            width: spacing(8);
            border-bottom: 1.25px solid color(blue, dark);
            transform: rotate(45deg);
          }
        }
      }
    }
    .modal__content {
      padding: spacing(8) spacing(12, 2) spacing(12);
      img {
        width: 100%;
      }
    }
  }
  .modal__overlay {
    background-color: color(black);
    opacity: 0;
    transition-duration: 300ms;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: opacity;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &.modal--open {
    pointer-events: auto;
    .modal__overlay {
      opacity: 0.5;
    }
    .modal__body {
      opacity: 1;
      transform: translate(-50%, -50%) translateY(0px);
    }
  }
  //desktopSD
  @media only screen and (max-width: breakpoint(desktopSD)) {
    .modal__body {
      width: 60vw;
    }
  }
  //Tablet
  @media only screen and (max-width: breakpoint(tablet)) {
    .modal__body {
      width: 80vw;
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .modal__body {
      width: 92vw;
      max-height: calc(100% - 32px);
      .modal__header {
        padding: spacing(8) spacing(8) 0;
      }
      .modal__content {
        padding: spacing(8) spacing(8) spacing(12);
      }
    }
  }
}
