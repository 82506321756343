@import "./src/styles/_foundation.scss";
.menu {
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  width: 0vw;
  height: 100vh;
  pointer-events: none;
  transition: transition(medium, basic, width);
  overflow: hidden;
  color: color(white);
  background: radial-gradient(
    circle at 0% 0%,
    color(blue, night) 0%,
    color(blue, dark) 100%
  );
  &.menu--open {
    width: 100vw;
    pointer-events: all;
    .menu__footer,
    .menu__body {
      opacity: 1;
      .menu__body__spacer__separator {
        height: 100%;
      }
    }
    .menu__body .menu__body__close button svg {
      transform: rotate(0deg) !important;
      transition-delay: 0s !important;
    }
  }
  .menu__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .menu__footer {
    opacity: 0;
    transition: transition(medium);
    transition-delay: 0.25s;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: spacing(12) spacing(16, 2);
    width: 100%;

    .menu__footer__links {
      font-family: font-family();
      font-weight: 400;
      a {
        color: color(white);
        text-decoration: none;
        display: inline-block;
        position: relative;

        margin: 0 spacing(4);
        &:first-child {
          margin-left: 0;
        }

        &:hover {
          color: color(blue, light);
        }
      }
    }
  }
  .menu__body {
    opacity: 0;
    transition: transition(medium, basic);
    transition-delay: 0.25s;
    max-height: calc(100vh - 75px);
    height: calc(100vh - 75px);
    overflow-y: auto;
    .menu__body__spacer__separator {
      border-left: spacing(1) solid color(gray, medium);
      display: inline-block;
      height: 0%;
      transition: transition(medium, basic, height);
      transition-delay: 0.35s;
    }
    .menu__body__secondary-menu__links-list {
      padding-bottom: spacing(10);
      a {
        color: color(white);
        text-decoration: none;
        display: block;
        position: relative;
        font-weight: 400;

        &:hover {
          color: color(blue, light);
        }
      }
    }
    .menu__body__content {
      padding-top: spacing(16);
      padding-bottom: spacing(16);
      max-height: calc(100vh - 160px);
      height: calc(100vh - 160px);
      .grid {
        height: 100%;
      }
      .menu__body__main-menu {
        padding: 0 spacing(9);
        .menu__body__main-menu__item {
          font-size: heading-font-size(h2);
          line-height: heading-line-height(h2);
          font-weight: 300;
          color: color(white);
          display: block;
          text-decoration: none;
          transition: transition();
          margin: 0;
          padding: spacing(5) 0;
          &:hover {
            color: color(blue, light);
          }
        }
      }
    }
    .menu__body__close {
      padding: spacing(9) spacing(16) spacing(5);
      button {
        font-weight: 400;
        background-color: transparent;
        padding: 0 spacing(2);
        margin: 0;
        border: none;
        display: flex;
        color: color(white);
        font-family: font-family();
        cursor: pointer;
        svg {
          width: spacing(8);
          height: spacing(8);
          fill: color(white);
          position: relative;
          transform: rotate(-120deg) !important;
          transition: transition(slow, basic, transform);
          transition-delay: 1s;
        }
        .menu__body__close__text {
          height: spacing(8);
          line-height: spacing(8);
          margin-left: spacing(4);
          font-family: font-family();
          font-weight: 600;
          color: color(white);
        }
        &:hover {
          svg {
            fill: color(blue, light);
          }
          .menu__body__close__text {
            color: color(blue, light);
          }
        }
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .menu__footer {
      position: relative;
      padding: spacing(13);
      font-size: font-size(xs);
      line-height: line-height(xs);
      .menu__footer__links {
        a {
          margin: 0;
          display: inline;
        }
      }
    }
    .menu__container {
      overflow-y: scroll;
    }

    .menu__body {
      position: relative;
      height: auto;
      max-height: none;
      .menu__body__content {
        height: auto;
        max-height: none;
        padding-top: spacing(8);
        padding-bottom: spacing(8);
        .menu__body__main-menu {
          padding: 0 spacing(6);
          .menu__body__main-menu__item {
            font-size: heading-font-size(h4);
            line-height: heading-line-height(h4);
          }
        }
        .menu__body__secondary-menu {
          grid-column-end: 22;
          grid-column-start: 4;
          padding-top: spacing(16);
        }
      }
      .menu__body__close {
        padding-left: spacing(5);
        padding-top: spacing(8);
      }
      .menu__body__spacer__separator {
        display: none;
      }
    }
  }
}
