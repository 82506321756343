@import "./src/styles/_foundation.scss";
.section-hero {
  &.section-hero--imageOnly {
    height: auto;
  }
  height: 100vh;
  .grid {
    height: 100vh;
  }
  h1,
  h4,
  h5,
  p {
    color: color(black);
  }
  h1 {
    font-size: heading-font-size(h2Hero);
    line-height: heading-line-height(h2Hero);
  }

  .section-hero__container {
    position: relative;
    height: 100%;
    .section-hero__container__wrapper {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translate(0, -50%);
    }
  }
  .section-hero__image {
    &.section-hero__image--only {
      img {
        object-fit: cover;
        object-position: 50% 50%;
        max-height: 630px;
        height: 100%;
        width: 100%;
      }
    }
    img {
      object-fit: cover;
      object-position: 50% 50%;
      height: 100%;
      width: 100%;
    }
    .section-hero__image__text {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: spacing(10);
      color: color(white);
      h5,
      h4, {
        color: color(white);
      }
      h5 {
        font-size: font-size(s);
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    height: auto;
    padding-bottom: spacing(10);
    .grid {
      height: auto;
    }
    .section-hero__image {
      height: 80vh;
      grid-column-end: span 24;
      position: relative;
      .section-hero__image__text {

        h5,
        h4,
        h2 {
          color: color(white);
        }
        h5 {
          font-size: font-size(s);
        }
      }
    }
    .section-hero__container {
      height: auto;
      position: relative;
      top: auto;
      left: auto;
      h1,
      h4,
      h5 {
        display: none;
      }
      .section-hero__container__wrapper {
        height: auto;
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0);
        padding-top: spacing(6);
      }
    }
  }
}
