@import "./src/styles/_foundation.scss";
.accesory {
  margin-bottom: spacing(10);
  .accesory__container {
    .accesory__name {
      h4 {
        margin: 0;
        padding-bottom: 0;
        color: color(blue, lake);
      }
      h5 {
        margin: 0;
        padding-bottom: spacing(4);
      }
    }
    .accesory__description {
      span {
        color: color(gray, medium);
      }
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    margin-bottom: spacing(16);
  }
}
