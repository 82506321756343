@import "./src/styles/_foundation.scss";
.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
  &.side-panel--small {
    .side-panel__body {
      width: 25vw;
      .side-panel__content {
        padding: spacing(8) spacing(8) spacing(12);
      }
    }
  }
  &.side-panel--large {
    .side-panel__body {
      width: 66vw;
    }
  }

  .side-panel__body {
    background-color: color(white);

    z-index: 20;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    overflow-y: auto;
    height: 100vh;
    transform: translateX(100%);
    opacity: 0;
    transition-property: all;
    transition-duration: 300ms, 300ms;
    transition-timing-function: ease, ease;
    width: 33vw;
    .side-panel__header {
      padding: spacing(8) spacing(12, 2);
      h4 {
        margin: 0;
        color: color(black);
      }
      .side-panel__header__back-button {
        position: absolute;
        right: spacing(6);
        top: spacing(6);
      }
      .side-panel__header__close {
        position: absolute;
        left: spacing(6);
        top: spacing(6);
        .side-panel__header__close__button {
          position: relative;
          width: spacing(12);
          height: spacing(12);
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            background-color: transparentize(color(gray, light), 0.6);
          }
          background-color: transparent;
          &:after {
            content: "";
            position: absolute;
            top: spacing(7);
            left: spacing(4);
            width: spacing(8);
            border-bottom: 1.25px solid color(blue, dark);
            transform: rotate(-45deg);
          }
          &:before {
            content: "";
            position: absolute;
            top: spacing(7);
            left: spacing(4);
            width: spacing(8);
            border-bottom: 1.25px solid color(blue, dark);
            transform: rotate(45deg);
          }
        }
      }
    }
    .side-panel__content {
      padding: spacing(8) spacing(12, 2) spacing(12);
    }
  }
  .side-panel__overlay {
    background-color: color(black);
    opacity: 0;
    transition-duration: 300ms;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: opacity;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &.side-panel--open {
    pointer-events: auto;
    .side-panel__overlay {
      opacity: 0.5;
    }
    .side-panel__body {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  //desktopSD
  @media only screen and (max-width: breakpoint(desktopSD)) {
    .side-panel__body {
      width: 60vw;
    }
  }
  //Tablet
  @media only screen and (max-width: breakpoint(tablet)) {
    .side-panel__body {
      width: 80vw;
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    &.side-panel--small {
      .side-panel__body {
        width: 95vw;
      }
    }
    &.side-panel--large {
      .side-panel__body {
        width: 95vw;
      }
    }
    .side-panel__body {
      width: 95vw;

      .side-panel__header {
        padding: spacing(8) spacing(8) spacing(12);
      }
      .side-panel__content {
        padding: spacing(8) spacing(8) spacing(12);
      }
    }
  }
}
