@import "./src/styles/_foundation.scss";
.seller {
  .seller__image {
    display: inline-block;
    width: spacing(16);
    vertical-align: top;
    .seller__avatar {
      width: spacing(14);
      height: spacing(14);
      border-radius: 50%;
      border: 1px solid color(blue, dark);
      overflow: hidden;
      img {
        width: spacing(14);
        height: spacing(14);
        object-fit: cover;
        object-position: center;
      }
      span {
        display: inline-block;
        text-align: center;
        width: spacing(14);
        height: spacing(14);
        line-height: spacing(14);
        font-size: font-size(s);
        text-transform: uppercase;
      }
    }
  }
  .seller__info {
    display: inline-block;
    .seller__name {
      padding-top: spacing(5);
    }
    .seller__contact {
      font-size: font-size(s);
      line-height: line-height(s);
      .seller__whatsapp,
      .seller__zoom {
        line-height: spacing(8);
        padding-bottom: spacing(2);
        a {
          line-height: spacing(8);
        }
        span {
          position: relative;
          top: spacing(3);
          img {
            width: spacing(8);
            margin-right: spacing(2);
          }
        }
      }
    }
  }
}
