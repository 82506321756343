@import "./src/styles/_foundation.scss";
.stage {
  background-color: color(gray, dark);
  min-height: 100vh;
  color: color(white);
  p,
  h1,
  h2 {
    color: color(white);
  }
  .stage__container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
  }
  .stage__image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    top: 0;
    left: 0;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(70%);
    }
  }
  @media only screen and (max-height: breakpoint(desktopHDHeight)) {
    .stage__description {
      display: none;
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .stage__container {
      top: auto;
      bottom: spacing(1);
    }
  }
}
