@import "./src/styles/_foundation.scss";
.section {
  padding-top: spacing(16);
  padding-bottom: spacing(10);
  &.section--first {
    padding-top: spacing(16, 2);
  }
  & + .section {
    padding-top: 0;
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    padding-top: spacing(8);
    padding-bottom: spacing(5);
    & + .section {
      padding-top: spacing(8);
    }
  }
}
