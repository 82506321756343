@import "./src/styles/_foundation.scss";
.technical-data {
  padding: spacing(11) 0 spacing(12);
  .technical-data__item {
    padding-left: spacing(16);
    display: inline-block;
    padding-top: spacing(6);
    padding-bottom: spacing(6);
    .technical-data__item__value {
      font-size: heading-font-size(h3);
      color: color(black);
      font-family: font-family(display);
      font-weight: 500;
      span {
        font-size: font-size();
        font-family: font-family();
        font-weight: normal;
      }
    }
  }
  .technical-data__catalog {
    width: 100%;
    height: spacing(2, 11);
    position: relative;
    margin-top: spacing(8);
    text-align: center;
    .technical-data__catalog__button {
      position: relative;
      z-index: 10;
      padding: 0 spacing(6);
      display: inline-block;
      background-color: color(white);
    }
    .technical-data__catalog__line {
      height: spacing(1);
      background-color: color(gray, tin);
      position: absolute;
      left: 0px;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
      width: 100%;
    }
  }
  &.technical-data--small {
    padding: spacing(1) 0 spacing(6);
    .technical-data__catalog {
      display: none;
    }
    .technical-data__item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &.technical-data--center {
    .technical-data__item {
      padding-left: spacing(6);
      padding-right: spacing(6);
    }
  }
  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    .technical-data__item {
      padding-left: 0;
      padding-right: spacing(8);
      padding-top: spacing(2);
      padding-bottom: spacing(2);
      .technical-data__item__name {
        font-size: font-size(s);
      }
      .technical-data__item__value {
        font-size: heading-font-size(h4);
        span {
          font-size: font-size(s);
        }
      }
    }
  }
}
