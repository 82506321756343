@import "./src/styles/_foundation.scss";
.main-link {
  font-weight: bold;
  padding-bottom: spacing(1);
  border-bottom: 1px solid currentcolor;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: color(gray, tin);
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
  .main-link__icon {
    padding-left: spacing(4);
    svg {
      width: spacing(6)-2px;
      height: spacing(6)-2px;
    }
  }
  &.main-link--small {
    &:hover {
      &:before {
        opacity: 0.2;
      }
      color: color(blue, dark);
    }
  }
}
