@import "./src/styles/_foundation.scss";
.text-area {
  width: 100%;
  textarea {
    width: 100%;
    font-family: font-family();
    font-size: font-size();
    line-height: line-height();
    border: none;
    border-bottom: 1px solid color(gray, tin);
    padding: spacing(2) 0;
    &:focus {
      border-bottom: 1px solid color(blue, dark);
    }
  }
  &.text-area--error {
    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="email"],
    input[type="tel"] {
      border-bottom: 1px solid color(red);
    }
  }
  .text-area__error-message {
    font-size: font-size(s);
    line-height: line-height(s);
    color: color(red);
  }
}
