@import "./src/styles/_foundation.scss";
.button {
  font-family: font-family();
  font-size: font-size(s);
  position: relative;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: 44px;
  min-width: 44px;
  cursor: pointer;
  color: color(white);
  padding: 0px spacing(12);
  border-width: spacing(1);
  border-style: solid;
  border-image: initial;
  margin: 0px;
  border-radius: 500px;
  outline: none;
  transition: transition();
  text-decoration: none;
  background: color(blue, dark);
  border-color: color(blue, dark);
  white-space: nowrap;
  span {
    position: relative;
    z-index: 10;
  }
  &[disabled] {
    opacity: 50%;
  }
  &:before {
    content: "";
    background-color: color(white);
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    border-radius: 500px;
    margin: -2px;
    opacity: 0;
    transition: transition(fast);
    pointer-events: none;
    z-index: 0;
  }
  &:hover {
    &:before {
      opacity: 0.2;
    }
  }

  &.button--white {
    background-color: color(white);
    color: color(blue, dark);
    border-color: color(white);
    &:before {
      background-color: color(blue, light);
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &.button--ghost {
    background-color: color(white);
    color: color(blue, dark);
    border-color: color(blue, dark);
    &:before {
      background-color: transparent;
    }
    &:hover {
      color: color(blue, link);
      border-color: color(blue, link);
    }
  }
  &.button--small {
    min-height: spacing(11);
    min-width: spacing(11);
    line-height: spacing(11);
    border-width: 1px;
    font-size: font-size(xs);
    &:before {
      background-color: color(gray, tin);
    }

    &.button--ghost {
      &:hover {
        color: color(blue, dark);
        border-color: color(blue, dark);
      }
    }
  }
  & + .button {
    margin-left: spacing(2);
  }

  //Mobile
  @media only screen and (max-width: breakpoint(mobile)) {
    display: block;
    width: 100%;
    text-align: center;
    & + .button {
      margin-left: 0;
      margin-top: spacing(4);
    }
  }
}

a + .button{
  margin-left: spacing(2);
}
//Mobile
@media only screen and (max-width: breakpoint(mobile)) {

  a + .button {
    margin-left: 0;
    margin-top: spacing(4);
  }
}
